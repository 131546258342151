
import { useContext } from 'react'
import P2 from '../images/p2.png'
import P3 from '../images/p3.png'
import thumbnail from '../images/thumbnail2.jpg'
import { NoDataFound } from '../Library/Module'
import { ThemeContext } from '../Context/Theme/Context'
import { NavLink } from 'react-router-dom'

const CategoryBox = () => {
    const { mainCategory } = useContext(ThemeContext)
    return (
        <>
            <div className="container pt-4">
                <div className="new_collection">
                    <div className="row">
                        <div className="col-md-12 col-sm-12"><h3 className="headingSection">Classical Homoeopathy</h3>
                        </div>
                    </div>
                    <NavLink to={'/category/potentised-dilutions'}>
                        <div className="boxSection">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-sm-6">
                                    <img src={P2} alt="" title="" />
                                </div>
                                <div className="col-md-7 col-sm-6">
                                    <div className="innerSection">
                                        <h3 className="headingSection2">Potentised Dilutions</h3>
                                        <p>Homeopathic remedies that harness the power of diluted natural substances prepared through our time tested process of dilution and succussion to harness the therapeutic power of natural substances.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to={'/category/mother-tinctures'}>
                        <div className="boxSection">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-sm-6">
                                    <img src={P3} alt="" title="" />
                                </div>
                                <div className="col-md-7 col-sm-6">
                                    <div className="innerSection">
                                        <h3 className="headingSection2">Mother Tinctures</h3>
                                        <p>Our range includes mother tinctures, which are concentrated herbal extracts used for various therapeutic purposes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
            <div className="categoryList categoryListHome">
                <div className="container">
                    <div className="col-md-12 col-sm-12"><h3 className="headingSection"> Combination Remedies & More ...</h3>
                        <div className="row">
                            {mainCategory?.length === 0 ? <NoDataFound></NoDataFound> :
                                mainCategory?.map((item: any) => {
                                    console.log(" item ----- ", item?.slug)
                                    return item?.slug !== "mother-tinctures" && item?.slug !== "potentised-dilutions" && <>
                                        <div className="col-md-4 mb-3">
                                            <NavLink to={`/category/${item?.slug}`}>
                                                <div className="inner">
                                                    <div className="imagePlaceholder">
                                                        <img src={item?.image ? process.env.react_app_base_url + "/" + item?.image : thumbnail} alt="" title=""></img>
                                                    </div>
                                                    <div className="innerText">

                                                        <NavLink to={`/category/${item?.slug}`}><h3 className="pageTitle pb-2">{item?.title} </h3></NavLink>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </>
                                })}

                            <div className="col-md-4 mb-3">
                                <NavLink to={`/category/`}>
                                    <div className="inner2">
                                        <NavLink to={`/category/`}><h3 className="pageTitle pb-2">Browse our product <br></br> catalog
                                        </h3></NavLink>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryBox