import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png'
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../Context/Theme/Context';
import stript from '../images/gurantee.png'
import thumbnail from '../images/thumbnail.jpg'
import { Button } from '../Library/Module';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  const { mainCategory, catlogData } = useContext(ThemeContext)
  console.log("mainCategory", mainCategory)
  console.log("catlogData", catlogData)
  const [list, setList] = useState([])

  useEffect(() => {
    if (mainCategory) {
      const newList: any = []
      mainCategory?.map((i: any) => {
        i.subMenu = catlogData?.filter((ab: any) => {
          return ab?.category === i?.slug
        })


        return newList.push(i)
      })
      console.log("newList", newList)
      setList(newList)
    }
  }, [mainCategory, catlogData])

  console.log("list", list)

  const MegaMenu = () => {
    alert("Hi")
  }

  const [hamburgerMenu, setHamburgerMenu] = useState(false)
  const [mobileOnly, setMobileOnly] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", resize);
    const windwoWidth = window.innerWidth <= 990;
    console.log("windwoWidth", window.innerWidth)
    setHamburgerMenu(windwoWidth)
    setMobileOnly(windwoWidth)
    resize();
  }, [])

  const handleOpen = () => {
    setHamburgerMenu(true)
  }

  const handleClose = () => {
    setHamburgerMenu(false)
  }

  const resize = () => {
    const windwoWidth = window.innerWidth <= 990;
    setHamburgerMenu(windwoWidth)
    setMobileOnly(windwoWidth)
  }

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12 col-6">
              <div className='logoWraper'>
                <NavLink to={'/'}><img src={Logo} title="" alt='' /></NavLink>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 col-6 text-right">
              <div className="top_nav">
                <img src={stript} title="" alt='' />
              </div>
              <div className={"main_nav"}>

                <ul>
                  <li> <NavLink to={'/'}>Home</NavLink> </li>
                  <li> <NavLink to={'/about-us'}>About Us</NavLink> </li>
                  {/* <li> <NavLink to={'/homeopathic-product-catalog'}>Catalog</NavLink> </li> */}
                  <li> <NavLink to={'/category'}>Products</NavLink>
                    <ul>
                      <div className='megaMenu'>
                        {list?.map((i: any) => {
                          return (
                            <li className=''>
                              {/* <p className='heading'>{i?.title}</p> */}
                              <NavLink to={`/category/${i?.slug}`}>{i?.title}</NavLink>
                              {/* <ul>
                                {i?.subMenu?.map((ai: any) => {
                                  console.log("subMenu", list)
                                  return (
                                    <li>
                                      <NavLink to={`/category/${i?.slug}/${ai?.slug}`}>{ai?.title}</NavLink>
                                    </li>
                                  )
                                })
                                }
                              </ul> */}
                            </li>
                          )
                        })}
                      </div>
                    </ul>
                  </li>
                  <li> <NavLink to={'/download-catalogue'}>Download Catalogue</NavLink> </li>
                  <li> <NavLink to={'/contact-us'}>Contact Us</NavLink> </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div className="headerMobile">
        {!hamburgerMenu &&
          <div className='overlay'></div>
        }
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-12 col-6">
              <div className='logoWraper'>
                <NavLink to={'/'}><img src={Logo} title="" alt='' /></NavLink>
              </div>
            </div>
            <div className="col-md-9 col-lg-12 col-6 text-right">

              {!hamburgerMenu ?
                <></>
                :
                <div className="hangerMenuOpen">
                  <Button
                    buttonStyleOutline
                    buttonStyleType="primary"
                    onClick={() => handleClose()} >
                    <span className='lineBox'>
                      <span className='line'></span>
                      <span className='line'></span>
                      <span className='line'></span>
                    </span>
                    <span>Menu</span>
                  </Button>
                </div>
              }

              <div className={hamburgerMenu ? `main_nav ` : "main_nav main_nav_mobile"}>

                {!hamburgerMenu &&
                  <>

                    <div className='megaMenuMobile'>
                      {!hamburgerMenu &&
                        <>
                          <div className='headerMobile'>
                            <div className="hangerMenuClose">
                              <Button onClick={() => handleOpen()}
                                buttonStyleOutline
                                buttonStyleType="primary">
                                <span className='lineBox'>
                                  <span className='line'></span>
                                  <span className='line'></span>
                                  <span className='line'></span>
                                </span>
                                <span>Close</span>
                              </Button>
                            </div>
                          </div>
                          <div className='clearfix'></div>
                        </>
                      }
                      <ul>
                        <li> <NavLink onClick={() => handleOpen()} to={'/'}>Home</NavLink> </li>
                        <li> <NavLink onClick={() => handleOpen()} to={'/about-us'}>About Us</NavLink> </li>
                        {/* <li> <NavLink onClick={() => handleOpen()} to={'/homeopathic-product-catalog'}>Catalog</NavLink> </li> */}
                        <li> <NavLink onClick={() => handleOpen()} to={'/category'}>Products</NavLink>
                          <ul>
                            <div className='megaMenu'>
                              {list?.map((i: any) => {
                                return (
                                  <li>
                                    <NavLink onClick={() => handleOpen()} to={`/category/${i?.slug}`}>{i?.title}</NavLink>
                                    <ul>
                                      {i?.subMenu?.map((ai: any) => {
                                        console.log("subMenu", list)
                                        return (
                                          <li>
                                            <NavLink onClick={() => handleOpen()} to={`/category/${i?.slug}/${ai?.slug}`}>{ai?.title}</NavLink>
                                          </li>
                                        )
                                      })
                                      }
                                    </ul>
                                  </li>
                                )
                              })}
                            </div>
                          </ul>
                        </li>
                        <li> <NavLink to={'/download-catalogue'}>Download Catalogue</NavLink> </li>
                        {/* <li> <NavLink onClick={() => handleOpen()} to={'/distributors'}>Distributors</NavLink> </li> */}
                        <li> <NavLink onClick={() => handleOpen()} to={'/contact-us'}>Contact Us</NavLink> </li>
                      </ul>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
