import React, { useEffect, useState, useMemo, useCallback } from "react";
import thumbnail from '../images/thumbnail.jpg'
import { SideDrawer } from "../Module";
import CustomTooltip from "../Tooltip/Tippy";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink, useNavigate, useParams } from "react-router-dom";

interface noDataFoundProps {
    images?: any;
    secondaryText?: any;
    title?: any;
    category?: any;
    showFullImage?: boolean
}
const Slider = ({
    images = [],
    secondaryText,
    title,
    category,
    showFullImage = true
}: noDataFoundProps): JSX.Element => {
    const { product_title } = useParams()
    const [open, setOpen] = useState(false)
    const [image, setImage] = useState<any>(undefined)
    useEffect(() => {
        setImage(images?.[0] ?? thumbnail)
    }, [images])
    const selectedImage = useCallback((data) => {
        setImage(data)
    }, [])

    const CloseDrawer = () => {
        setOpen(false)
    };
    const [lightboxImage, setLightboxImage] = useState(null)
    const Inquiry = (image: any) => {
        setOpen(true)
        setLightboxImage(image)
    }

    return (
        <>
            {showFullImage ?
                <div className="slider">
                    <div className="full">
                        <div className="cursor_pointer" onClick={() => Inquiry(image)}>
                            <div className="imagePlaceholder">
                                <LazyLoadImage
                                    src={images?.length === 0 ? image : process.env.react_app_base_url + "/" + image}
                                    // width={product_title ? 400 : 288}
                                    // height={product_title ? 400 : 288}
                                    alt={title}
                                    effect="blur"
                                    threshold={200}
                                />
                            </div>
                            {/* <img src={images?.length === 0 ? image : process.env.react_app_base_url + "/" + image} alt="" title="" /> */}

                        </div>
                    </div>
                    {images?.length === 1 ? '' :
                        <div className="thumbnai">
                            <ul>
                                {images?.length === 0 ? '' : images?.map((i: any) => {
                                    return (<>
                                        <li className="cursor_pointer" onClick={() => showFullImage ? selectedImage(i) : Inquiry(i)}>
                                            <LazyLoadImage
                                                src={process.env.react_app_base_url + "/" + i}
                                                width={60}
                                                height={60}
                                                alt={title}
                                                effect="blur"
                                            />
                                        </li>
                                    </>)
                                })}
                                <div className="clearfix"></div>
                            </ul>
                        </div>
                    }
                </div>
                :
                <>
                    {images?.length === 1 ? '' :
                        <div className="slider">
                            <div className="thumbnai">
                                <ul>
                                    {/* filterData?.sort((a, b) => b[sortKey] - a[sortKey]) */}
                                    {images?.length === 0 ? '' : images?.map((i: any) => {
                                        return (<>
                                            <li className="cursor_pointer" onClick={() => showFullImage ? selectedImage(i) : Inquiry(i?.image[0])}>
                                                {/* <img src={process.env.react_app_base_url + "/" + i?.image[0]} alt="" title="" /> */}
                                                <LazyLoadImage
                                                    effect="blur"
                                                    src={process.env.react_app_base_url + "/" + i?.image[0]} width={150} height={150} alt={title} />
                                                <p>{i?.title}</p>
                                            </li>
                                        </>)
                                    })}
                                    <div className="clearfix"></div>
                                </ul>
                            </div>
                        </div>
                    }
                </>
            }
            {open && lightboxImage &&
                <SideDrawer
                    size={'650px'}
                    type="lightbox"
                    pagetitle={`${title && title} - ${category && category} `}
                    action={CloseDrawer}>
                    <div className="popoverInner">
                        {/* <img src={lightboxImage ? process.env.react_app_base_url + "/" + lightboxImage : ''} title="" alt="" /> */}
                        <LazyLoadImage effect="blur"
                            src={lightboxImage ? process.env.react_app_base_url + "/" + lightboxImage : ''} alt={title} />
                    </div>
                </SideDrawer>
            }
        </>
    );
};

export default Slider;
